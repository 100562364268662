import { NewLogo } from "@kanpla/ui";
import { Trans, useTranslation } from "react-i18next";
import Reveal from "react-reveal/Reveal";
import { useWindowScrollPosition } from "rooks";
import Login from "./Login";

const Hero = () => {
  const { t } = useTranslation(["homepage"]);
  const textShadow = "1px 1px 3px #00000033";
  const { scrollY } = useWindowScrollPosition();

  return (
    <div className="min-h-screen md:min-h-0 w-full flex flex-col items-center p-5 bg-black justify-between flex-1 -mb-48 lg:mb-0">
      <div
        className="absolute bottom-0 right-0 w-full h-full z-0 top-0 transition-transform duration-500"
        style={{
          transformOrigin: "right center",
          maxHeight: "96vh",
          transform: `scale(1.15) translateY(${-scrollY / 15}px)`,
          // backgroundColor: '#0C4329',
          backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0.25) 48.96%, #000000 100%), url("/images/homepage/kanpla-canteen-food.jpg")`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
        }}
      ></div>

      <div className="custom-container my-4 md:my-8 lg:my-10 flex justify-between items-center relative z-30">
        <div
          className="mt-8 md:mt-0 text-background-primary md:mb-16 lg:mb-0 iPadPro:mb-64 w-28"
          style={{ transformOrigin: "left top", transform: "scale(1.5)" }}
        >
          <NewLogo type="white" />
        </div>

        <Login className="hidden lg:flex" />
      </div>

      <div style={{}} className=" relative my-auto pb-16 lg:pb-0">
        <Reveal effect="gentleSlideLeft">
          <h2
            style={{
              textShadow,
              lineHeight: "1.1em",
            }}
            // tracking-tight leading-tight
            className="text-4xl font-bold text-background-primary leading-tight md:text-5xl lg:text-6xl lg:pb-2 md:text-center mx-auto"
          >
            <Trans t={t} i18nKey="homepage:order-delicious-food">
              Bestil lækker mad
              <br /> produceret af din
              <br /> lokale kantine
            </Trans>
          </h2>

          <Login className="lg:hidden mt-8" />
        </Reveal>
      </div>
    </div>
  );
};

export default Hero;
