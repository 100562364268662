import { useTranslation } from "react-i18next";
import Head from "next/head";
import Features from "./features";
import Footer from "./footer";
import Hero from "./hero";

const Index = () => {
  const { t } = useTranslation(["homepage"]);
  return (
    <>
      <Head>
        <title>{t("homepage:kanpla")}</title>
      </Head>
      <div className="bg-text-primary">
        <div className="lg:min-h-screen flex flex-col items-center justify-between">
          <Hero />
          <Features />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Index;
