import React from "react";
import { NewLogo } from "@kanpla/ui";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { AppContext } from "./contextProvider";

const LandingPageButton = ({
  text,
  backgroundOpacity = false,
  className = "",
  onClick,
}) => {
  return (
    <div
      className={`flex hover:opacity-90 cursor-pointer transition-opacity w-full md:max-w-sm h-16 md:h-14 rounded-lg justify-center tracking-wide items-center ${className} ${
        backgroundOpacity ? "text-white" : "font-semibold text-gray-900"
      }`}
      style={{
        background: `${
          backgroundOpacity ? "rgba(255, 255, 255, 0.2)" : "#FFFFFF"
        }`,
      }}
      onClick={onClick}
    >
      {text}
    </div>
  );
};

const Landing = () => {
  const { t } = useTranslation(["translation"]);
  const router = useRouter();
  const { customBranding } = useContainer(AppContext);

  const backgroundImage =
    customBranding?.custom?.images?.landingBackground ||
    "/images/cooking-hero-join.jpeg";

  return (
    <div id="landing-app-fullscreen" className="w-full h-full relative">
      <div
        className="relative w-full h-full"
        style={{
          background: `linear-gradient( rgba(0,0,0,0.5), rgba(0,0,0,0.5) ), url("${backgroundImage}")`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="relative md:flex md:justify-center md:items-center w-full h-full p-4 z-40 min-h-screen">
          <div className="flex flex-col justify-between md:justify-center w-full h-full p-4 fixed inset-0">
            <div className="w-full flex justify-center mt-20 md:mt-0">
              <NewLogo centered type="white" style={{ maxWidth: 200 }} />
            </div>
            <div className="flex flex-col md:justify-center md:items-center mb-8 md:mt-20">
              {!customBranding?.posone && (
                <LandingPageButton
                  onClick={() => router.push("/signup")}
                  text={t("translation:sign-up")}
                />
              )}
              {customBranding?.posone && customBranding?.url && (
                <a
                  href={`https://${customBranding?.url}.smartbetaling.dk/#register`}
                  className="w-full md:max-w-sm no-underline"
                >
                  <LandingPageButton
                    onClick={() => {
                      return;
                    }}
                    text={t("translation:sign-up")}
                  />
                </a>
              )}
              <LandingPageButton
                text={t("translation:log-in")}
                onClick={() => router.push("/login")}
                backgroundOpacity={true}
                className="mt-4"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
