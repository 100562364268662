import { NewLogo } from "@kanpla/ui";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import CompanyInfo from "../CompanyInfo";
import Login from "./Login";

const Footer = () => {
  const { t } = useTranslation(["homepage"]);
  const [infoOpen, setInfoOpen] = useState(false);

  const linkStyle =
    "mr-3 hover:text-text-secondary  block text-sm text-text-secondary mt-2";

  return (
    <div className=" w-full border-t border-divider-main flex flex-col justify-center items-center pt-20 relative z-30">
      <div className="mb-12 text-background-primary flex flex-col items-center w-36">
        <NewLogo type="white" />
        <div className="mt-2 text-sm">
          {t("homepage:your-canteen-platform")}
        </div>
      </div>

      <div className="mb-12">
        <Login />
      </div>

      <div className="flex flex-wrap justify-center items-center lg:pb-8 px-6 mb-8 lg:mb-0">
        <button onClick={() => setInfoOpen(true)} className={linkStyle}>
          {t("homepage:company-info")}
        </button>
        <a
          href="/kanpla_handelsvilkar.pdf"
          target="_blank"
          className={linkStyle}
        >
          {t("homepage:terms-of-trade")}
        </a>
        <a
          href="/kanpla_persondatapolitik.pdf"
          target="_blank"
          className={linkStyle}
        >
          {t("homepage:personal-data-policy")}
        </a>
        <a
          href="/kanpla_cookie_policy.pdf"
          target="_blank"
          className={linkStyle}
        >
          {t("homepage:cookie-policy")}
        </a>
      </div>

      <CompanyInfo open={infoOpen} setOpen={setInfoOpen} />
    </div>
  );
};

export default Footer;
