import React, { useEffect, useState } from "react";
import Homepage from "../components/homepage";
import LandingApp from "../components/LandingApp";

const Index = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  const landingPageRoutes = ["www.kanpla.dk", "kanpla.dk", "test.kanpla.dk"];

  const isWebViewWrapper =
    typeof window !== "undefined" && window?.["isRenderedInWebView"];

  const isLandingPage =
    typeof window !== `undefined` &&
    !isWebViewWrapper &&
    landingPageRoutes.includes(window.location.hostname);

  if (loading) return null;

  return isLandingPage ? <Homepage /> : <LandingApp />;
};

export default Index;
