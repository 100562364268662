import { useTranslation } from "react-i18next";
import Link from "next/link";
import React from "react";

interface Props {
  className?: string;
}

const Login = ({ className = "" }: Props) => {
  const { t } = useTranslation(["translation"]);
  return (
    <div className={`flex ${className}`}>
      <Link href="/login">
        <button
          className="btn lg text-background-primary font-medium active:text-background-primary"
          style={{ background: "rgba(255,255,255,.2)" }}
        >
          {t("translation:log-in")}
        </button>
      </Link>
      <div className="ml-3">
        <Link href="/signup">
          <button className="btn lg bg-primary-main font-medium">
            {t("translation:sign-up-long")}
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Login;
