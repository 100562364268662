// import { Parallax, ParallaxLayer } from '@react-spring/parallax'
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Trans, useTranslation } from "react-i18next";
import React from "react";
import Zoom from "react-reveal/Zoom";

const Features = () => {
  const { t } = useTranslation(["homepage"]);
  const Box = ({ title, subtitle, illustration, link }) => (
    <Zoom duration={1000}>
      <a
        href={link}
        target="_blank"
        className="text-background-primary hover:text-background-primary block px-8 py-6 relative mx-4 rounded-lg mb-6 flex-1"
        style={{ background: `rgba(255, 255, 255, 0.1)` }}
        rel="noreferrer"
      >
        <img
          src={illustration}
          alt={t("homepage:user-friendly-only")}
          className="absolute top-0 right-0 w-10 m-3"
        />
        <div>{title}</div>
        <p className="text-xl lg:text-2xl font-semibold mt-4 leading-7 mb-4">
          {subtitle}{" "}
          <FontAwesomeIcon
            icon={faChevronRight}
            style={{ marginTop: 3, marginLeft: 2 }}
          />
        </p>
      </a>
    </Zoom>
  );
  return (
    <div className="py-16 lg:pb-4 w-full flex justify-center flex-col lg:flex-row max-w-4xl">
      <Box
        title={t("homepage:for-kitchens")}
        subtitle={
          <Trans t={t} i18nKey="homepage:increase-sales">
            Styrk relationen
            <br /> og øg salget
          </Trans>
        }
        illustration="/images/homepage/canteen-kitchen.svg"
        link="https://erhverv.kanpla.dk/"
      />
      <Box
        title={t("homepage:for-users")}
        subtitle={
          <Trans t={t} i18nKey="homepage:digital-path">
            Den digitale vej til
            <br /> din frokostpause
          </Trans>
        }
        illustration="/images/homepage/phone-ordering.svg"
        link="https://bruger.kanpla.dk/"
      />
    </div>
  );
};

export default Features;
