import { DrawerOrModal } from "@kanpla/ui";
import moment from "moment";
import { Trans, useTranslation } from "react-i18next";
import React from "react";

const CompanyInfo = ({ open, setOpen }) => {
  const { t } = useTranslation(["components"]);
  return (
    <DrawerOrModal
      open={open}
      setOpen={setOpen}
      title="Kanpla ApS"
      className="text-center"
      containerClassName="flex"
    >
      <p className="mb-4 text-text-primary">
        Inge Lehmanns Gade 10, 5<br />
        8000 Aarhus C, Danmark
      </p>
      <p className="text-text-primary">
        Mail:{" "}
        <a href="mailto:support@kanpla.dk" className="font-medium">
          support@kanpla.dk
        </a>
      </p>
      <p className="mb-4 text-text-primary">
        <Trans t={t} i18nKey="components:tel">
          Tlf:
          <a href="tel:+4539393009" className="font-medium">
            {{ value: "+45 39 39 30 09" }}
          </a>
        </Trans>
      </p>
      <p className="text-sm text-text-secondary">
        Copyright © 2020 - {moment().format("YYYY")} <br /> Kanpla Aps - CVR:
        40369813
      </p>
    </DrawerOrModal>
  );
};

export default CompanyInfo;
